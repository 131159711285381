@import "~@styles/variables.scss";
.base {
  min-height: $desktop-btn-min-height;
  font-size: $desktop-btn-font-size;

  @media (max-width: 767px) {
    min-height: $mobile-btn-min-height;
    font-size: $mobile-btn-font-size;

    span.ant-typography {
      font-size: $mobile-btn-font-size;
    }
  }

  min-height: $desktop-btn-min-height;
  font-size: $desktop-btn-font-size;

  @media (max-width: 767px) {
    min-height: $mobile-btn-min-height;
    font-size: $mobile-btn-font-size;

    span.ant-typography {
      font-size: $mobile-btn-font-size;
    }
  }
}

.base.large {
  min-height: $desktop-btn-lg-min-height;
  font-size: $desktop-btn-lg-font-size;

  @media (max-width: 767px) {
    min-height: $mobile-btn-lg-min-height;
    font-size: $mobile-btn-lg-font-size;

    span.ant-typography {
      font-size: $mobile-btn-lg-font-size;
    }
  }
}

.responsiveBtn {
  @media (max-width: 767px) {
    width: 100%;
  }
}

.responsiveBtn.actionBtn {
  @media (max-width: 767px) {
    margin-top: 18px;
  }
}

.fullWidth {
  width: 100%;
}

.disabled {
  cursor: not-allowed;
}

.primary {
  background-color: $primary;

  &:hover {
    background-color: $primary-light;
  }

  &.disabled:hover {
    color: $white !important;
  }
}

.disabled {
  opacity: 0.4;
}

.secondary,
.secondary:active,
.secondary:focus {
  background-color: $secondary;
  color: $white;

  &:hover {
    div {
      color: $primary !important;
    }
  }
}

.primary.disabled {
  background: #8aabda !important;
  border-color: #8aabda !important;
  cursor: default;
  color: $white;
}

.secondary.disabled {
  background: $secondary-opaque;
  cursor: default;
  color: $white;
}

.pullRight {
  float: right;
}
