.ant-select-auto-complete {
  .ant-input {
    border-radius: 0px !important; // prevents rounding of highlighted area
  }
}

.ant-radio-button-wrapper:first-child {
  border-radius: 4px 0px 0px 4px !important;
}

.ant-radio-button-wrapper:last-child {
  border-radius: 0px 4px 4px 0px !important;
}

.ant-form-item-control-input-content {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
