// Alert - success
.ant-alert-success {
  .ant-alert-message,
  .ant-alert-description,
  .anticon-close {
    color: $success !important;
  }
}

// Alert - danger
.ant-alert-error {
  .ant-alert-message,
  .ant-alert-description,
  .anticon-close {
    color: $danger !important;
  }
}

// Alert - warning
.ant-alert-warning {
  .ant-alert-message,
  .ant-alert-description,
  .anticon-close {
    color: $warning !important;
  }
}

.ant-alert {
  margin-bottom: 10px !important;
}
