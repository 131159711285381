@import "~@styles/variables.scss";
// -------------------------------------- CITY CAROUSEL ---------------------------------------------------------
.cd-headline.rotate-1 .cd-words-wrapper {
  perspective: 300px;
  display: inline-block;
}
.cd-headline {
  white-space: nowrap;
}

.cd-headline.rotate-1 b {
  opacity: 0;
  transform-origin: 50% 100%;
  transform: rotateX(180deg);
  display: inline-block;
  position: absolute;
}

.cd-headline.rotate-1 b.is-visible {
  position: absolute;
  opacity: 1;
  transform: rotateX(0deg);
  animation: cd-rotate-1-in 2.5s;
}

.cd-headline.rotate-1 b.is-hidden {
  position: absolute;
  transform-origin: 50% 100%;
  transform: rotateX(180deg);
  animation: cd-rotate-1-out 2.5s;
}

.carousel-city {
  white-space: nowrap;
  float: left;
  display: block;
  position: relative;
}

@keyframes cd-rotate-1-in {
  0% {
    transform: rotateX(180deg);
    opacity: 0;
  }
  35% {
    transform: rotateX(120deg);
    opacity: 0;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(360deg);
    opacity: 1;
  }
}

@keyframes cd-rotate-1-out {
  0% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  35% {
    transform: rotateX(0deg);
    opacity: 1;
  }
  65% {
    opacity: 0;
  }
  100% {
    transform: rotateX(180deg);
    opacity: 0;
  }
}

.carousel-container {
  -webkit-backface-visibility: hidden;
  text-align: center;
  white-space: nowrap !important;
}

.carousel-left-container {
  @extend .carousel-container;
  padding-right: 370px;
}

.carousel-right-container {
  @extend .carousel-container;
  padding-right: 316px;
}
