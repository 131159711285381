$desktop-modal-margin-top: 100px;

.modal-dialog {
  @media (max-width: $mobile-header-breakpoint) {
    z-index: $modal-z-index;
  }

  @media (min-width: $mobile-header-breakpoint) {
    margin-top: $desktop-modal-margin-top;
  }
}

$div-border-radius: 4px;

$modal-header-height-web: 60px;
$modal-title-line-height-web: 40px; // account for padding on top and bottom
$modal-title-font-size-web: 20px;

$modal-header-height-mobile: 60px;
$modal-title-line-height-mobile: 40px; // account for padding on top and bottom
$modal-title-font-size-mobile: 18px;

$alert-modal-footer-height: 110px;

// Modals that contain forms
div:not(.market-alert-modal) > .modal-dialog:not([role='dialog']) {
  @media (max-width: $mobile-header-breakpoint) {
    margin: 0;
    height: 100%;

    .modal-content {
      height: 100%;
      display: flex;
      flex-direction: column;

      .modal-header {
        height: $modal-header-height-web;
      }

      .modal-body {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        p {
          padding: 10px;
        }
      }

      .modal-footer {
        height: $alert-modal-footer-height;
      }

      .modal-footer .btn + .btn {
        margin-bottom: 0;
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
}

.modal-title {
  //font-size: $modal-title-font-size-web;
  //line-height: $modal-title-line-height-web; // taken from bootstrap-sass modals

  @media (max-width: $mobile-header-breakpoint) {
    //font-size: $modal-title-font-size;
    //line-height: $modal-title-line-height-mobile;
    font-size: 26px;
  }
}

// Alert modals and React modals
.market-alert-modal,
.modal-dialog[role='dialog'] {
  .modal-content {
    .modal-header {
      .close {
        font-size: 32px;
        font-weight: 100;
        color: #63686c;
        opacity: 0.8;
      }
    }
  }

  ul {
    padding-inline-start: 20px;
  }

  @media (max-width: $mobile-header-breakpoint) {
    margin: 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 56px;

    .modal-content {
      .modal-header {
        height: $modal-header-height-web;
      }

      .modal-body {
        overflow-y: auto;

        p {
          padding: 10px;
        }
      }

      .modal-footer {
        height: $alert-modal-footer-height;
      }

      .modal-footer .btn + .btn {
        margin-bottom: 0;
        margin-left: 0px;
        margin-top: 10px;
      }
    }
  }
}

.modal-content {
  border-top: 6px solid $secondary;
  border-radius: $div-border-radius;

  .modal-header {
    padding: 10px 20px 10px 20px;
    @media screen and (max-width: $mobile-header-breakpoint) {
      padding: 10px;
    }
  }

  .modal-body {
    padding: 10px;
    overflow-y: scroll !important;
    height: 100%;
  }

  @media (min-width: $mobile-header-breakpoint) {
    .modal-body {
      padding: 10px;
      overflow-y: scroll !important;
      height: 100%;
    }
  }
}

.confirm-modal {
  .modal-body {
    @media (max-width: $mobile-header-breakpoint) {
      height: calc(100% - 120px);
    }
  }
}

.modal-body::-webkit-scrollbar {
  display: none;
}

.modal-body {
  .submit-btn-container {
    text-align: right;
  }
}

.info-btn {
  cursor: pointer;
}

// Truncates titles if they overflow
.ant-modal-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
}

.ant-modal.page-antd-modal[role='dialog'] {
  .ant-modal-content {
    @media (min-width: $mobile-header-breakpoint) {
      min-height: 300px; // Prevents the modal from only loading a header
    }
  }
}
/*
 * General styles to apply to all Antd modals
 * Defines behavior for full screen mobile modals
 */
.ant-modal,
.ant-modal.medium-antd-modal[role='dialog'],
.ant-modal.large-antd-modal[role='dialog'],
.ant-modal.page-antd-modal[role='dialog'] {
  &.mobile-full-screen-modal {
    .ant-modal-content {
      @media (max-width: $mobile-header-breakpoint) {
        height: 90vh;
      }
    }
  }

  .ant-modal-content {
    border-top: 6px solid $secondary;
    color: $default-text-color;
    font-family: $font;
    border-radius: $div-border-radius;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); // taken from bootstrap-sass modals
    background-clip: inherit;
    button.ant-modal-close {
      height: $modal-header-height-web;

      span.ant-modal-close-x {
        color: $default-text-color;
        height: $modal-header-height-web;
        line-height: $modal-header-height-web; // should occupy full height
      }
    }

    .ant-modal-header {
      padding: 10px 20px;
      height: $modal-header-height-web;

      .ant-modal-title {
        color: $default-text-color;
        font-size: $modal-title-font-size-web !important;
        font-weight: normal;
        line-height: $modal-title-line-height-web; // taken from bootstrap-sass modals
        padding-left: 10px;
      }
    }

    .ant-modal-body {
      padding: 16px 10px;
    }
  }

  @media (min-width: $mobile-header-breakpoint) {
    .ant-modal-body {
      overflow-y: auto;
      max-height: calc(100vh - 200px);
    }
  }

  @media (max-width: $mobile-header-breakpoint) {
    width: 100%;
    max-width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    top: 0px;
    padding-bottom: 0px;

    .ant-modal-content {
      display: flex;
      flex-direction: column;

      .ant-modal-body {
        overflow-y: auto;
      }

      button.ant-modal-close {
        height: $modal-header-height-mobile;

        span.ant-modal-close-x {
          width: $modal-header-height-mobile;
          height: $modal-header-height-mobile;
          line-height: $modal-header-height-mobile; // should occupy full height
        }
      }

      .ant-modal-header {
        height: $modal-header-height-mobile;

        .ant-modal-title {
          color: $default-text-color;
          line-height: $modal-title-line-height-mobile;
          font-weight: normal;
          font-size: $modal-title-font-size-mobile !important;
          padding-left: 10px;
        }
      }
    }
  }
}

.ant-modal-wrap {
  @media (max-width: $mobile-header-breakpoint) {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 56px;
  }
}

.page-modal-wrapper,
.large-modal-wrapper {
  &.ant-modal-wrap {
    padding: 0px;
  }

  @media (max-width: $mobile-header-breakpoint) {
    width: 100%;
  }

  .ant-modal {
    @media (max-width: $mobile-header-breakpoint) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 750px !important;
    }
    @media (min-width: 992px) {
      width: 900px !important;
    }
    @media (min-width: 1200px) {
      width: 1150px !important;
    }
  }
}


.page-modal-wrapper,
.large-modal-wrapper {
  &.ant-modal-wrap {
    padding: 0px;
  }

  @media (max-width: $mobile-header-breakpoint) {
    width: 100%;
  }

  .ant-modal {
    @media (max-width: $mobile-header-breakpoint) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 750px !important;
    }
    @media (min-width: 992px) {
      width: 900px !important;
    }
    @media (min-width: 1200px) {
      width: 1150px !important;
    }
  }
}

.medium-modal-wrapper {
  @media (max-width: $mobile-header-breakpoint) {
    width: 100%;

    &.ant-modal-wrap {
      padding: 0px;
    }
  }

  .ant-modal.mobile-full-screen-modal {
    @media (max-width: $mobile-header-breakpoint) {
      width: 100%;
    }
  }
}

.hide-cancel-modal-wrapper {
  div.ant-modal-content > button.ant-modal-close {
    display: none !important;
  }
}

.page-modal-wrapper {
  .ant-modal {
    @media (min-width: $mobile-header-breakpoint) {
      top: 25px !important;
    }
  }
}

.page-modal-wrapper {
  &.ant-modal-wrap {
    padding: 0px;
  }

  @media (max-width: $mobile-header-breakpoint) {
    width: 100%;
  }

  .ant-modal {
    @media (max-width: $mobile-header-breakpoint) {
      width: 100%;
    }
    @media (min-width: 768px) {
      width: 750px !important;
    }
    @media (min-width: 992px) {
      width: 900px !important;
    }
    @media (min-width: 1200px) {
      width: 1150px !important;
    }
  }
}
/*
 * ALERT MODAL
 *
 * Alert modal using Antd's Modal.update
 * This is a really dumb style thing I have to do because Antd uses a different set of containers
 * for 'confirmation' modals, and won't render header elements
 *
 * Eventually, we will just rewrite this behavior ourselves
 */
.ant-alert-modal {
  .ant-modal-confirm-content {
    margin-top: 0px !important;
  }

  .ant-modal-close {
    display: block !important;
  }

  .ant-modal-body {
    padding: 0px !important;

    .ant-modal-header {
      display: block !important;

      .ant-modal-title {
        padding: 0px !important;
      }
    }

    .ant-modal-content-body {
      padding: 20px 20px !important;
    }

    .ant-modal-confirm-btns {
      display: none !important;
    }
  }
}
/*
 * ALERT MODAL
 *
 * Alert modal using Antd's Modal.update
 * This is a really dumb style thing I have to do because Antd uses a different set of containers
 * for 'confirmation' modals, and won't render header elements
 *
 * Eventually, we will just rewrite this behavior ourselves
 */
.ant-modal-confirm-confirm {
  .ant-modal-confirm-content {
    margin-top: 0px !important;
  }

  .ant-modal-close {
    height: 44px !important;

    @media (max-width: $mobile-header-breakpoint) {
      height: 44px !important;
    }

    .ant-modal-close-x {
      height: 44px !important;
      line-height: 44px !important;

      @media (max-width: $mobile-header-breakpoint) {
        height: 44px !important;
        line-height: 44px !important;
      }
    }
  }

  .ant-modal-body {
    padding: 0px !important;

    .ant-modal-header {
      display: block !important;

      .ant-modal-title {
        padding: 0px !important;
      }
    }

    .ant-modal-confirm-title {
      padding: 10px 10px !important;
      border-bottom: 0.55px solid $surface;
    }

    .ant-modal-confirm-content {
      padding: 10px 10px 20px 10px !important;
      border-bottom: 0.55px solid $surface;
    }

    .ant-modal-confirm-btns {
      margin-top: 0px !important;
      padding: 10px 10px !important;
    }
  }
}
