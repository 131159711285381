@import "~@styles/variables.scss";
.container {
  position: fixed;
  top: $header-height-desktop + 10px;
  left: 50px;
  width: 300px;
  height: 300px;
  z-index: 99999;
  background-color: transparentize($black, $transparent-light);
  color: $white;
}

.text {
  font-size: 10px;
  line-height: 1;
}
