@import "~@styles/variables.scss";
.base {
  height: 55px;
  width: 94.6px;
}

.base.white {
  background: url(https://cdn.flymoment.com/images/logos/moment-logo-white.svg) no-repeat;
}

.base.grey {
  background: url(https://cdn.flymoment.com/images/logos/moment-logo-surface-dark.svg) no-repeat;
}

.base.primary {
  background: url(https://cdn.flymoment.com/images/logos/moment-logo.svg) no-repeat;
}

.base.small {
  height: 30px;
  width: 51.6px;
}

.base.big {
  width: 200px;
  height: 116px;
}
