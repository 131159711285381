@import "~@styles/variables.scss";
@import './vendor/fontawesome.css';
@import './vendor/weatherIcons.scss';
@import './vendor/paymentIcons';
@import './vendor/antdModal';
@import './vendor/antdComponents';
@import './vendor/reactCodeInput';
@import './reactAwesomeSlider';

html,
body {
  padding: 0;
  margin: 0;
  font-family: $font;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: $font !important;
}

span {
  font-family: $font !important;
}

p {
  font-size: $title-text-size;
  line-height: $title-text-size + 8px;
  margin-bottom: 8px;
}

.content-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
}

@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: bold;
  src: url('https://cdn.flymoment.com/fonts/colfax/colfaxBold.eot');
  src: url('https://cdn.flymoment.com/fonts/colfax/colfaxBold.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxBold.woff') format('woff'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxBold.ttf') format('truetype'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxBold.svg#colfaxBold') format('svg');
}

@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: 100;
  src: url('https://cdn.flymoment.com/fonts/colfax/colfaxLight.eot');
  src: url('https://cdn.flymoment.com/fonts/colfax/colfaxLight.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxLight.woff') format('woff'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxLight.ttf') format('truetype'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxLight.svg#colfaxLight') format('svg');
}

@font-face {
  font-family: 'Colfax';
  font-style: normal;
  font-weight: normal;
  src: url('https://cdn.flymoment.com/fonts/colfax/colfaxRegular.eot');
  src: url('https://cdn.flymoment.com/fonts/colfax/colfaxRegular.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxRegular.woff') format('woff'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxRegular.ttf') format('truetype'),
    url('https://cdn.flymoment.com/fonts/colfax/colfaxRegular.svg#colfaxRegular') format('svg');
}

.hidden-mobile-landscape {
  @media (max-width: 600px) {
    display: none;
  }
}

.hidden-xs {
  @media (max-width: 768px) {
    display: none;
  }
}

.hidden-sm {
  @media (max-width: 992px) {
    display: none;
  }
}

.hidden-mobile {
  @media screen and (max-width: $mobile-header-breakpoint) {
    display: none;
  }
}

.hidden-desktop {
  @media screen and (min-width: $mobile-header-breakpoint) {
    display: none;
  }
}

// Set up equiv height flex containers
.ant-row.is-flex {
  display: flex;
  flex-wrap: wrap;
}
.ant-row.is-flex > [class*='ant-col-'] {
  display: flex;
  flex-direction: column;
}

.ant-row.is-flex {
  .about-card,
  .card-content {
    display: flex;
    flex-direction: column;
  }
}

.ant-row.is-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.ant-row.is-flex > [class*='ant-col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.about-card {
  margin-top: -62px;

  @media screen and (max-width: $mobile-header-breakpoint) {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  flex: 1;

  .card-content {
    flex: 1;
  }
}

.text-right {
  text-align: right;
}

.ant-select-override {
  .ant-select-selector {
    padding: 0px !important;
  }
}

.bag-select-error {
  .ant-select-selector {
    border-color: $danger !important;
  }
  select {
    border-color: $danger !important;
  }
}

.no-margin-input-sm {
  @media (max-width: 767px) {
    .ant-form-item {
      margin: 0px;
    }
  }
}

.thin-margin-input-sm {
  @media (max-width: 767px) {
    .ant-form-item {
      margin-top: 6px;
      margin-bottom: 6px;
    }
  }
}

.ellipsis-container {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  .ant-typography {
    white-space: nowrap;
  }
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

.modal-scroll-disabled {
  overflow: hidden;
  position: fixed;
}