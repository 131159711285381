.ant-list-item-meta {
  padding-left: 10px;

  .ant-list-item-meta-title {
    font-size: $medium-text-size;
    //font-weight: $bold;
    color: $primary;
    line-height: 1;
    margin-bottom: 0;
  }

  .ant-list-item-meta-description {
    //font-size: $default-text-size;
    //line-height: 1;
    margin-top: 4px;
  }
}

.ant-list-header {
  padding-top: 5px;
  padding-bottom: 5px;
}

.passenger-search-container {
  .ant-list-item {
    //border-radius: $border-radius;
  }

  .ant-list-items {
    border-radius: $border-radius;
  }
}

.no-border-list {
  .ant-list {
    .ant-list-items {
      .ant-list-item {
        border-bottom: none;
      }
    }
  }
}

.tagged-footer-list {
  .ant-list {
    .ant-list-items {
      .ant-list-item {
        padding-top: 6px;
        padding-bottom: 6px;

        &:last-child {
          border-bottom: none !important;
          padding-bottom: 0px;
        }
      }
    }

    .ant-list-footer {
      padding-top: 0px;
      padding-bottom: 12px;
    }
  }
}
