@import "~@styles/variables.scss";
.body {
  width: 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  background-color: $primary;
}
.container {
  padding-top: 50px;

  @media (max-width: $mobile-header-breakpoint) {
    padding-top: 10vh;
  }

  background-color: $primary;

  margin: auto;
  text-align: center;
}

.logoContainer {
  margin-bottom: 50px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.footer {
  position: absolute;
  bottom: 0;
  height: 100px;
  @media screen and (max-width: $mobile-header-breakpoint) {
    display: none;
  }
  text-align: center;
  width: 100%;
}
