@import "~@styles/variables.scss";
.DesignText {
  color: $default-text-color;
  font-family: $font;
}

.DesignText.primaryColor {
  color: $primary;
}

.DesignText.secondaryColor {
  color: $secondary;
}

.DesignText.successColor {
  color: $success;
}

.DesignText.dangerColor {
  color: $danger;
}

.DesignText.warningColor {
  color: $warning;
}

.DesignText.whiteColor {
  color: $white;
}

.DesignText.surfaceColor {
  color: $surface-almost-dark;
}

.DesignText.smallSize {
  font-size: $small-text-size;

  &.responsive {
    @media screen and (max-width: $mobile-header-breakpoint) {
      font-size: $small-text-size;
      line-height: $small-text-size + 8px;
    }
  }
}

.DesignText.defaultSize {
  font-size: $default-text-size;

  &.responsive {
    @media screen and (max-width: $mobile-header-breakpoint) {
      font-size: $default-text-size-mobile;
      line-height: $default-text-size-mobile + 8px;
    }
  }
}

.DesignText.titleSize {
  font-size: $title-text-size;

  &.responsive {
    @media screen and (max-width: $mobile-header-breakpoint) {
      font-size: $title-text-size-mobile;
      line-height: $title-text-size-mobile + 8px;
    }
  }
}

.DesignText.mediumSize {
  font-size: $medium-text-size;

  &.responsive {
    @media screen and (max-width: $mobile-header-breakpoint) {
      font-size: $medium-text-size-mobile;
      line-height: $medium-text-size-mobile + 8px;
    }
  }
}

.DesignText.largeSize {
  font-size: $large-text-size;

  &.responsive {
    @media screen and (max-width: $mobile-header-breakpoint) {
      font-size: $large-text-size-mobile;
      line-height: $large-text-size-mobile + 8px;
    }
  }
}

.DesignText.xlSize {
  font-size: $xl-text-size;

  &.responsive {
    @media screen and (max-width: $mobile-header-breakpoint) {
      font-size: $xl-text-size-mobile;
      line-height: $xl-text-size-mobile + 8px;
    }
  }
}

.DesignText.xxlSize {
  font-size: $xxl-text-size;

  &.responsive {
    @media screen and (max-width: $mobile-header-breakpoint) {
      font-size: $xxl-text-size-mobile;
      line-height: $xxl-text-size-mobile + 8px;
    }
  }
}

.DesignText.hugeSize {
  font-size: $huge-text-size;

  &.responsive {
    @media screen and (max-width: $mobile-header-breakpoint) {
      font-size: $huge-text-size-mobile;
      line-height: $huge-text-size-mobile + 8px;
    }
  }
}

.DesignText.strong {
  font-weight: $bold;
}

.DesignText.thin {
  font-weight: $thin;
}

.DesignText.italic {
  font-style: italic;
}

.DesignText.pullLeft {
  float: left;
}

.DesignText.pullRight {
  float: right;
}

.DesignText.richText {
  white-space: pre-wrap;
}

.DesignText.strikethrough {
  text-decoration: line-through;
}
