$payment-icon-width: 40px;

.add-cc-icon {
  min-width: $payment-icon-width;
  min-height: 20px;
  text-align: center;
}

.cc-icon {
  background: url('https://cdn.flymoment.com/images/icons/cards/card.svg') no-repeat center center;
  background-size: 40%;
  min-width: $payment-icon-width;
  min-height: 20px;
  max-width: $payment-icon-width;
}

.ach-icon {
  background-size: 40%;
  min-width: $payment-icon-width;
  min-height: 20px;
  max-width: $payment-icon-width;
}

.cc-number + .cc-icon {
  position: absolute;
  display: block;
  right: 20px;
  top: 50%;
  margin-top: -10px;
  width: 28px;
  height: 19px;
  background-size: 100%;
}

.cc-number.visa + .cc-icon,
.cc-icon.visa {
  background-image: url('https://cdn.flymoment.com/images/icons/cards/visa.png');
}

.cc-number.mastercard + .cc-icon,
.cc-icon.mastercard {
  background-image: url('https://cdn.flymoment.com/images/icons/cards/mastercard.png');
}

.cc-number.amex + .cc-icon,
.cc-icon.amex {
  background-image: url('https://cdn.flymoment.com/images/icons/cards/amex.png');
}

.cc-number.dinersclub + .cc-icon,
.cc-icon.dinersclub {
  background-image: url('https://cdn.flymoment.com/images/icons/cards/dinersclub.png');
}

.cc-number.jcb + .cc-icon,
.cc-icon.jcb {
  background-image: url('https://cdn.flymoment.com/images/icons/cards/jcb.png');
}

.cc-number.discover + .cc-icon,
.cc-icon.discover {
  background-image: url('https://cdn.flymoment.com/images/icons/cards/discover.png');
}
