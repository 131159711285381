.time-picker {
  .ant-select-selection-item {
    margin-left: 11px;
  }
}

.flight-search-form-pax-select {
  .ant-select-selection-item {
    text-overflow: unset;
  }
}

.ant-select-selector {
  text-align: left;
}
